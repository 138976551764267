import {
  Box,
  Button,
  chakra,
  Link,
  Stack,
} from '@fluidtruck/core'
import Container from 'components/container'
import Header from 'components/header'
import NextLink from 'next/link'
import * as React from 'react'
import { DiGithubBadge } from 'react-icons/di'
import { FaArrowRight } from 'react-icons/fa'

// const Feature = ({ title, icon, children, ...props }) => {
//   return (
//     <Box
//       bg='white'
//       rounded='12px'
//       shadow='base'
//       p='40px'
//       _dark={{ bg: 'gray.700' }}
//       {...props}
//     >
//       <Flex
//         rounded='full'
//         w='12'
//         h='12'
//         bg='teal.500'
//         align='center'
//         justify='center'
//       >
//         <Icon fontSize='24px' color='white' as={icon} />
//       </Flex>
//       <Heading as='h3' size='md' fontWeight='semibold' mt='1em' mb='0.5em'>
//         {title}
//       </Heading>
//       <Text fontSize='lg' opacity={0.7}>
//         {children}
//       </Text>
//     </Box>
//   )
// }

const HomePage = () => {
  return (
    <>
      <Header />
      <Box mb={20}>
        <Box as="section" pb={{ base: '0', md: '5rem' }} pt="6rem">
          <Container>
            <Box textAlign="center">
              <chakra.h1
                fontFamily="heading"
                fontSize={{ base: '2.25rem', sm: '2rem', lg: '3rem' }}
                fontWeight="extrabold"
                letterSpacing="tighter"
                lineHeight="1.2"
                maxW="16ch"
                mb="16px"
                mx="auto"
              >
                Tidal Component Library
              </chakra.h1>

              <Link
                _dark={{ color: 'gray.400' }}
                color="gray.500"
                fontSize={{ base: 'lg', lg: 'xl' }}
                maxW="560px"
                mt="6"
                mx="auto"
              >
                Tidal is a collection of components, built around Chakra UI.
                It exposes Chakra components that have been styled to our brand's specifications.
                It also exposes custom built components that are not available with Chakra.
              </Link>

              <Stack
                direction={{ base: 'column', sm: 'row' }}
                justify="center"
                mt="10"
                spacing="4"
              >
                <NextLink href="/getting-started" passHref>
                  <Button
                    as="a"
                    fontSize="1.2rem"
                    h="4rem"
                    px="40px"
                    rightIcon={<FaArrowRight fontSize="0.8em" />}
                    size="lg"
                  >
                    Get Started
                  </Button>
                </NextLink>
                <Button
                  as="a"
                  fontSize="1.2rem"
                  h="4rem"
                  href="https://github.com/chakra-ui/chakra-ui/"
                  leftIcon={<DiGithubBadge size="1.5em" />}
                  px="40px"
                  size="lg"
                  target="__blank"
                  variant="outline"
                >
                  GitHub
                </Button>
              </Stack>
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  )
}

export default HomePage
